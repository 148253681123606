import React, { useEffect, useState, useContext } from 'react';

const Sidebar = ({
    logoImage,
    type,
    focus,
    aumTier,
    clientSince,
    clientExcerpt
}) => {

    return (
        <>
            <div className="col-span-3 mt-6">
                <div className="flex flex-col items-center justify-center">
                    <div className="flex flex-col">
                        <div className="sidebar-border-y p-4 flex justify-center">
                            <img src={logoImage?.sourceUrl} alt="logo" />
                        </div>
                        <div className="sidebar-border">
                            <p className="sidebar-title">Type</p>
                            <p className="sidebar-content">{type}</p>
                        </div>
                        <div className="sidebar-border">
                            <p className="sidebar-title">Focus</p>
                            <p className="sidebar-content">{focus}</p>
                        </div>
                        <div className="sidebar-border">
                            <p className="sidebar-title">AUM Tier</p>
                            <p className="sidebar-content">{aumTier}</p>
                        </div>
                        <div className="sidebar-border">
                            <p className="sidebar-title">Client Since</p>
                            <p className="sidebar-content">{clientSince}</p>
                        </div>
                        <div className="sidebar-border">
                            <p className="sidebar-title">Client Excerpt</p>
                            <p className="sidebar-content">{clientExcerpt}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;



